import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 25 15">
    <path
      fill={color}
      d="M0.2,1.8l1.5-1.5c0.4-0.4,0.9-0.4,1.3,0l8.8,8.9c0.4,0.4,0.9,0.4,1.3,0L22,0.3c0.4-0.4,0.9-0.4,1.3,0l1.5,1.5
	c0.4,0.4,0.4,0.9,0,1.3L13.1,14.7c-0.4,0.4-0.9,0.4-1.3,0L0.3,3.1C-0.1,2.7-0.1,2.2,0.2,1.8z"
    />
  </svg>
);
